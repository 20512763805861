<template>
  <div class="px-4">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon">
                <i class="cuis-gem card-icon-font"></i>
              </div>
              <div>
                <h5 class="mb-0"><b>FIN Performographics</b></h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-row class="card-body">
        <b-col cols="12 pb-3">
          <p>
            Created exclusively by FI Navigator, performographics are the latest
            development in account-based marketing (ABM) prospect
            identification. Performographics allow users to identify prospect
            financial institutions by primary performance challenges (ex. fee
            generation or core funding) based on exception versus peer
            institutions. Now users can identify institutions by an existing
            pain point in profitability, growth or risk. Once a target list is
            generated you can filter the institutions through firmographics (ex.
            size or type). Try it out now.
          </p>
          <b-row>
            <b-col cols="5 pb-3">
              <multiselect
                class="simple-select"
                :show-labels="false"
                placeholder="Please select a product"
                label="product_name"
                :disabled="categories.length === 0"
                v-model="category"
                :searchable="false"
                :options="categories"
              ></multiselect>
            </b-col>
            <b-col cols="2" class="center-text">
              -
            </b-col>
            <b-col cols="5 pb-3">
              <multiselect
                class="simple-select"
                :show-labels="false"
                placeholder="Please select a theme"
                v-model="theme"
                label="theme_name"
                @input="onThemeSelected"
                :disabled="category === null"
                :searchable="false"
                :options="themes"
              ></multiselect>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import router from '@/router'
import Multiselect from 'vue-multiselect'

export default {
  name: 'performographics',
  components: {
    Multiselect
  },
  data() {
    return {
      category: null,
      categories: [],
      themesFromServer: {},
      theme: null
    }
  },
  computed: {
    themes() {
      if (this.category === null) {
        return []
      } else {
        return this.themesFromServer[this.category.product_id]
      }
    }
  },
  methods: {
    onThemeSelected() {
      router.push({
        name: 'performographics_report',
        query: {
          product_id: this.category.product_id,
          theme_id: this.theme.theme_id
        }
      })
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      document.title = 'FIN Performographics'
      vm.$http.get('api/performographics/category_themes').then(response => {
        vm.categories = response.data.categories
        vm.themesFromServer = response.data.themes
      })
    })
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  height: 16rem;
}
.center-text {
  text-align: center;
}
</style>
